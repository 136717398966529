import Vue from 'vue'

export const state = {
  current: null,
}

export const getters = {}

export const mutations = {}

export const actions = {
  async verifyFetch({ commit }, data) {
    try {
      const response = await Vue.axios.post('/verify-box', {
        barcode: data.barcode,
        birthdate: data.birthdate,
        amplitude_id: data.amplitude_id
      })

      // TODO: Check & Save response
      return response
    } catch (error) {
      console.log(error.response.status)
      if (error.response.status == 404) return error.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
