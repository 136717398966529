const data = {
  title: 'Finger Prick Only Flow',
  sections: [
    {
      title: 'Welcome',
      steps: [
        {
          component: 'StartKit',
          config: {},
        },
        {
          component: 'WelcomeChecklist',
          config: {
            title: 'Do these before you begin',
            instructions: [
              {
                icon: 'cup-icon.svg',
                text: 'Drink 2 glasses of water.',
              },
              {
                icon: 'desk-icon.svg',
                text: 'Go to a quiet spot with a table and chair.',
              },
              {
                icon: 'wifi-icon.svg',
                text: 'Connect your phone to Wi-Fi.',
              },
            ],
          },
        },
      ],
    },
    // {
    //   title: 'User Data Collection',
    //   steps: [
    //     {
    //       component: 'UserInfoBDO',
    //       config: {},
    //     },
    //   ],
    // },
    // {
    //   title: 'Terms',
    //   steps: [
    //     {
    //       component: 'TermsOfServiceBDO',
    //       config: {},
    //     },
    //     {
    //       component: 'NeedSignatureBDO',
    //       config: {},
    //     },
    //     {
    //       component: 'SignatureCapture',
    //       config: {},
    //     },
    //   ],
    // },
    {
      title: 'Permissions',
      steps: [
        {
          component: 'EnablePermissions',
          config: {
            allowProceedWithoutVideo: true,
          },
        },
        {
          component: 'StartKitChecklist',
          config: {
            items: {
              minutesFree: 'I have 25 minutes free now to complete the Kit',
              drankWater: 'I drank 2 glasses of water',
              understandRecording: 'I understand that my actions will be video-recorded',
              connectedToWiFi: "Please make sure you're connected to Wi-Fi for a better experience",
            },
          },
        },
      ],
    },
    {
      title: 'Videos',
      steps: [
        {
          component: 'VolumeCheck',
          config: {},
        },
        {
          component: 'VideoStage',
          config: {
            minNetworkQuality: 1,
            videoID: '804024282',
            stopOnPreflightFail: false,
            data: [
              {
                fullScreen: true,
                title: 'coachMark',
                prompt: 'coachMark',
                step: null,
                actionButton: { type: 'done' },
                instructions: [],
              },
              {
                title: 'Setup',
                prompt: 'Setup',
                step: null,
                actionButton: { type: 'done' },
                instructions: [],
              },
              {
                title: 'Overview',
                prompt: 'Kit overview',
                step: null,
                actionButton: { type: 'done' },
                instructions: [
                  {
                    text: 'Take phone stand from red sheet',
                    image: '/bdo/bdo_lh_hi/thumbnails/1-1.jpg',
                    displayAt: 5.5,
                  },
                  {
                    text: 'Open phone stand to tallest position',
                    image: '/bdo/bdo_lh_hi/thumbnails/1-2.jpg',
                    displayAt: 10,
                  },
                  {
                    text: 'Place phone on stand ~ 1 foot away, so app can see both you and table',
                    image: '/bdo/bdo_lh_hi/thumbnails/1-3.jpg',
                    displayAt: 17,
                  },
                ],
                confirmationDialog: {
                  icon: 'cup-icon.svg',
                  prompt: 'Did you drink two glasses of water?',
                  confirmBtn: 'Yes',
                  denyBtn: 'No',
                  denyModal: 'WhyHydrate',
                },
              },
              {
                title: 'Finger Prick',
                prompt: 'Finger Prick overview',
                step: '1 of 11',
                actionButton: { type: 'done', duration: null },
                instructions: [],
              },
              {
                title: 'Finger Prick',
                prompt: 'Finger Prick contents',
                step: '2 of 11',
                actionButton: { type: 'done' },
                instructions: [
                  { text: 'Open Box', image: '/bdo/bdo_lh_hi/thumbnails/2b-1.jpg', displayAt: 0 },
                ],
              },
              {
                title: 'Finger Prick',
                prompt: 'Prepare materials',
                step: '3 of 11',
                overlay: 'SelfieTopHalf',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Remove Accessories envelope',
                    image: '/bdo/bdo_lh_hi/thumbnails/2c-1.jpg',
                    displayAt: 0,
                  },
                  {
                    text: 'Unwrap items for later use',
                    image: '/bdo/bdo_lh_hi/thumbnails/2c-2.jpg',
                    displayAt: 11.5,
                  },
                ],
              },
              {
                title: 'Finger Prick',
                prompt: 'Set up collection tube',
                step: '4 of 11',
                overlay: 'BloodSampleWithSelfie',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Insert blood tube into stand',
                    image: '/bdo/bdo_lh_hi/thumbnails/2d-1.jpg',
                    displayAt: 9,
                  },
                  {
                    text: 'Insert funnel into blood tube',
                    image: '/bdo/bdo_lh_hi/thumbnails/2d-2.jpg',
                    displayAt: 13,
                  },
                ],
              },
              {
                title: 'Finger Prick',
                prompt: 'Test the yellow lancet',
                step: '5 of 11',
                overlay: 'SelfieTopHalf',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Remove safety pin from yellow lancet',
                    image: '/bdo/bdo_lh_hi/thumbnails/2e-1.jpg',
                    displayAt: 11,
                  },
                  {
                    text: 'Press yellow lancet against white dot on foam',
                    image: '/bdo/bdo_lh_hi/thumbnails/2e-2.jpg',
                    displayAt: 24,
                  },
                ],
              },
              {
                title: 'Finger Prick',
                prompt: 'Prepare for a micro blood draw',
                step: '6 of 11',
                overlay: 'BloodSampleWithSelfie',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Remove safety pin from 1 blue lancet',
                    image: '/bdo/bdo_lh_hi/thumbnails/2f-1.jpg',
                    displayAt: 2,
                  },
                ],
              },
              {
                title: 'Finger Prick',
                prompt: 'Warm your hand',
                step: '7 of 11',
                overlay: 'SelfieTopHalf',
                actionButton: { type: 'timer', duration: 60 },
                instructions: [
                  {
                    text: 'Click to activate warmer',
                    image: '/bdo/bdo_lh_hi/thumbnails/2gh-a-1.jpg',
                    displayAt: 12,
                  },
                  {
                    text: 'Warm hand for 1 minute',
                    image: '/bdo/bdo_lh_hi/thumbnails/2gh-a-2.jpg',
                    displayAt: 23,
                  },
                  {
                    text: 'If too hot, take off, wait then reapply',
                    image: '/bdo/bdo_lh_hi/thumbnails/2gh-a-3.jpg',
                    displayAt: 28,
                  },
                ],
              },
              {
                title: 'Finger Prick',
                prompt: 'Using the compressor',
                step: '8 of 11',
                overlay: null,
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Unwrap the finger compressor',
                    image: '/bdo/bdo_lh_hi/thumbnails/2gh-b-1.jpg',
                    displayAt: 8,
                  },
                  {
                    text: 'Take alcohol pad & sanitize finger',
                    image: '/bdo/bdo_lh_hi/thumbnails/2gh-b-2.jpg',
                    displayAt: 12,
                  },
                  {
                    text: 'Place your left middle finger through the loop of the compressor',
                    image: '/bdo/bdo_lh_hi/thumbnails/2gh-b-3.jpg',
                    displayAt: 26,
                  },
                ],
                informativeDialog: {
                  image: '/shared/thumbnails/compressor.png',
                  prompt: 'Are you holding the device exactly like this?',
                  confirmBtn: 'Yes',
                  denyBtn: 'No',
                  denyModal: 'HoldFingerCompressor',
                },
              },
              {
                title: 'Finger Prick',
                prompt: 'Do a micro blood draw',
                step: '9 of 11',
                specimenType: 'blood',
                specimenIndex: '1',
                overlay: 'BloodSampleWithSelfie',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Prick tip of middle finger FIRMLY',
                    image: '/bdo/bdo_lh_hi/thumbnails/2gh-c-1.jpg',
                    displayAt: 9,
                  },
                  {
                    text: 'Slowly pull & release device to collect blood',
                    image: '/bdo/bdo_lh_hi/thumbnails/2gh-c-2.jpg',
                    displayAt: 28,
                  },
                  {
                    text: 'Fill the tube to the black line (~5 mins)',
                    image: '/bdo/bdo_lh_hi/thumbnails/2gh-c-3.jpg',
                    displayAt: 44,
                  },
                ],
                confirmationDialog: {
                  icon: 'blood-fill-line.svg',
                  prompt: 'Did you fill the tube to the line?',
                  confirmBtn: 'Yes',
                  denyBtn: 'No',
                },
              },
              {
                title: 'Finger Prick',
                prompt: 'Bandage your finger',
                step: '10 of 11',
                overlay: 'SelfieTopHalf',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  { text: 'Use gauze', image: '/bdo/bdo_lh_hi/thumbnails/2i-1.jpg', displayAt: 0 },
                  {
                    text: 'Use bandage',
                    image: '/bdo/bdo_lh_hi/thumbnails/2i-2.jpg',
                    displayAt: 4,
                  },
                ],
              },
              {
                title: 'Finger Prick',
                prompt: 'Remove the funnel',
                step: '11 of 11',
                overlay: null,
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Remove funnel',
                    image: '/bdo/bdo_lh_hi/thumbnails/2j-a-1.jpg',
                    displayAt: 2,
                  },
                  {
                    text: 'Gently invert tube 10 times',
                    image: '/bdo/bdo_lh_hi/thumbnails/2j-a-2.jpg',
                    displayAt: 7,
                  },
                ],
              },
              {
                title: 'Centrifuge',
                prompt: 'Sample Prep Overview',
                step: '1 of 4',
                overlay: 'SelfieTopHalf',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Take blood tube from stand',
                    image: '/bdo/bdo_lh_hi/thumbnails/6a-1.jpg',
                    displayAt: 16,
                  },
                  {
                    text: 'Make sure funnel was removed',
                    image: '/bdo/bdo_lh_hi/thumbnails/6a-2.jpg',
                    displayAt: 19,
                  },
                  {
                    text: 'Put blood tube in spinner',
                    image: '/bdo/bdo_lh_hi/thumbnails/6a-3.jpg',
                    displayAt: 36,
                  },
                ],
              },
              {
                title: 'Centrifuge',
                prompt: 'Turn spinner on',
                step: '2 of 4',
                overlay: 'CentrifugeWithSelfieVideo',
                actionButton: {
                  type: 'custom',
                  text: 'Start Timer',
                },
                instructions: [
                  {
                    text: 'Turn on spinner',
                    image: '/bdo/bdo_lh_hi/thumbnails/6b-1.jpg',
                    displayAt: 8,
                  },
                ],
              },
              {
                title: 'Centrifuge',
                prompt: 'Trivia Video',
                fullScreen: true,
                actionButton: {
                  type: 'done',
                  duration: null,
                },
              },
              {
                title: 'Centrifuge',
                prompt: 'Turn off spinner',
                step: '3 of 4',
                overlay: 'CentrifugeWithSelfieVideo',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Turn off spinner',
                    image: '/bdo/bdo_lh_hi/thumbnails/6c-1.jpg',
                    displayAt: 3.5,
                  },
                ],
              },
              {
                title: 'Centrifuge',
                prompt: 'Put blood tube in bag',
                step: '4 of 4',
                overlay: 'BloodSampleWithSelfie',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Put tube in packing container',
                    image: '/bdo/bdo_lh_hi/thumbnails/6de-1.jpg',
                    displayAt: 0,
                  },
                  {
                    text: 'Place packing container in bio-bag',
                    image: '/bdo/bdo_lh_hi/thumbnails/6de-2.jpg',
                    displayAt: 15,
                  },
                  { text: 'Seal bag', image: '/bdo/bdo_lh_hi/thumbnails/6de-3.jpg', displayAt: 21 },
                ],
              },
              {
                title: 'Packing Up',
                prompt: 'Put away centrifuge',
                step: '1 of 6',
                overlay: null,
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Pack up spinner in the green box',
                    image: '/bdo/bdo_lh_hi/thumbnails/6g-1.jpg',
                    displayAt: 0,
                  },
                ],
              },
              {
                title: 'Packing Up',
                prompt: 'Put away blood sample',
                step: '2 of 6',
                overlay: null,
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Pack up the blood specimen tube and bag into the blue box',
                    image: '/bdo/bdo_lh_hi/thumbnails/6h-1.jpg',
                    displayAt: 0,
                  },
                ],
              },
              {
                title: 'Packing Up',
                prompt: 'Start packing',
                step: '3 of 6',
                overlay: null,
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Remove the return sticker',
                    image: '/bdo/bdo_lh_hi/thumbnails/7a-1.jpg',
                    displayAt: 3.5,
                  },
                  {
                    text: 'Remove the tasty treat from box and enjoy',
                    image: '/bdo/bdo_lh_hi/thumbnails/7a-2.jpg',
                    displayAt: 8,
                  },
                ],
              },
              {
                title: 'Packing Up',
                prompt: 'Do a final check',
                step: '4 of 6',
                overlay: null,
                actionButton: { type: 'done', duration: null },
                instructions: [],
              },
              {
                title: 'Packing Up',
                prompt: 'Seal and ship box',
                step: '5 of 6',
                overlay: 'SelfieTopHalf',
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Put green sample prep box into the shipping box',
                    image: '/bdo/bdo_lh_hi/thumbnails/7c-1.jpg',
                    displayAt: 0,
                  },
                  {
                    text: 'Put blue finger prick box into the shipping box',
                    image: '/bdo/bdo_lh_hi/thumbnails/7c-2.jpg',
                    displayAt: 3,
                  },
                  {
                    text: 'Seal the shipping box',
                    image: '/bdo/bdo_ed/thumbnails/7c-3.jpg',
                    displayAt: 6,
                  },
                ],
              },
              {
                title: 'Packing Up',
                prompt: 'Drop off box',
                step: '6 of 6',
                overlay: null,
                actionButton: { type: 'done', duration: null },
                instructions: [
                  {
                    text: 'Verify return shipping label already placed on box',
                    image: '/bdo/bdo_lh_hi/thumbnails/7d-1.jpg',
                    displayAt: 0,
                  },
                  {
                    text: 'Drop box off at local UPS store',
                    image: '/bdo/bdo_lh_hi/thumbnails/7d-2.jpg',
                    displayAt: 11,
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      title: 'Thank You',
      steps: [
        {
          component: 'ThankYou',
          config: {
            dropLocation: 'UPS',
          },
        },
      ],
    },
  ],
}

export default data
