<template lang="pug">
#app-layout(:class="{ landscape: forceLandscape }")
  #close(v-if="this.$route.meta.webViewContainer", @click="exit")
    icon(data="@icon/close.svg")
  component(v-if="isStandalone", :is="(this.$route.meta.layout || 'Default') + 'Layout'")
    RouterView(:key="$route.fullPath")
  AddToHomescreen(v-else)
</template>

<script>
export default {
  props: {},

  data() {
    return {
      androidHash: null,
      iosStandalone: null,
      window: {},
    }
  },

  computed: {
    forceLandscape,
    isStandalone,
  },

  watch: {},
  beforeCreate() {
    if (localStorage.getItem('redirect') === 'true' && this.$route.query.r === '413') {
      localStorage.setItem('redirect', false)
    } else if (localStorage.getItem('redirect') === 'true') {
      window.location.replace(process.env.VUE_APP_NEW_URL)
    }
    if (this.$route.query.r === '1') {
      const randomNumber = Math.random()
      if (randomNumber < Number(process.env.VUE_APP_RATIO)) {
        localStorage.setItem('redirect', true)
        window.location.replace(process.env.VUE_APP_NEW_URL)
      } else {
        localStorage.setItem('redirect', false)
      }
    }
  },

  mounted() {
    this.androidStandalone =
      window.matchMedia('(display-mode: fullscreen)').matches ||
      window.matchMedia('(display-mode: standalone)').matches ||
      window.matchMedia('(display-mode: minimal-ui)').matches
    this.iosStandalone = window.navigator.standalone
    this.$breakpoint.build()
  },

  methods: {
    exit,
  },

  components: {
    AddToHomescreen: () => import('@/components/AddToHomescreen'),
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function forceLandscape() {
  return this.$breakpoint.orientation == 'portrait' && this.$app.forceLandscape
}

function isStandalone() {
  return this.iosStandalone || this.androidStandalone || this.$route.meta.webViewContainer
}

function exit() {
  ReactNativeWebView.postMessage('user_exited')
}
</script>
